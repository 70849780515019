import React, { useState } from "react";

import "./ButtonSwap.css";

 

const About = () => {

  const [activeNavItem, setActiveNavItem] = useState(0);

 

  const handleNavItemClick = (index) => {

    setActiveNavItem(index);

  };

 

  return (

    <div className="about-container">

      <nav className="nav">

        <ul>

          <NavItem

            index={0}

            activeNavItem={activeNavItem}

            onClick={handleNavItemClick}

            label="UTILITY"

          />

          <NavItem

            index={1}

            activeNavItem={activeNavItem}

            onClick={handleNavItemClick}

            label="STAKING"

          />

          <NavItem

            index={2}

            activeNavItem={activeNavItem}

            onClick={handleNavItemClick}

            label="BURNING"

          />

          <NavItem

            index={3}

            activeNavItem={activeNavItem}

            onClick={handleNavItemClick}

            label="SECURITY"

          />

          <NavItem

            index={4}

            activeNavItem={activeNavItem}

            onClick={handleNavItemClick}

            label="CREDIBILITY"

          />

          <NavItem

            index={5}

            activeNavItem={activeNavItem}

            onClick={handleNavItemClick}

            label="SCALABILITY"

          />

        </ul>

      </nav>

 

      <div className="content">

        <ContentArea

          index={0}

          activeNavItem={activeNavItem}

          content="  Token will be used for the INOCYX’s NFT platform, INOCYX’s Land
          
          NFT, and other future metaverse projects where users benefit from IYX. Utility token for
          
          INOCYX’s metaverse business interactions and other activities. "

        />

        <ContentArea

          index={1}

          activeNavItem={activeNavItem}

          content="

          Staking is commonly associated with proof-of-stake (PoS) and delegated proof-of-stake (DPoS) blockchain networks, although other consensus mechanisms like proof-of-authority (PoA) also allow for staking
          It involves holding a certain amount of a specific cryptocurrency in a wallet and staking those funds to support the network's security, consensus mechanism, and other functions"

        />

        <ContentArea

          index={2}

          activeNavItem={activeNavItem}

          content="Burning in the context of cryptocurrencies and blockchain refers to the intentional and permanent removal of a certain number of tokens or coins from circulation. This process effectively reduces the total supply of a cryptocurrency, which can have various implications for its value, scarcity, and ecosystem. "

        />

        <ContentArea

          index={3}

          activeNavItem={activeNavItem}

          content="  Coin security is a critical aspect of the cryptocurrency ecosystem. Ensuring the security of coins, tokens, and digital assets is essential to protect users' investments, prevent fraud, and maintain trust within the blockchain and crypto space. Coin security encompasses various measures and practices that individuals, exchanges, wallet providers, and blockchain projects need to implement to safeguard digital assets"

        />

        <ContentArea

          index={4}

          activeNavItem={activeNavItem}

          content=" Coin credibility is a vital factor in the world of cryptocurrencies and blockchain technology. It refers to the perceived trustworthiness, legitimacy, and reliability of a particular coin or token within the broader cryptocurrency ecosystem. Credibility is crucial for fostering user trust, attracting investments, and ensuring the long-term success of a cryptocurrency project. "

        />

        <ContentArea

          index={5}

          activeNavItem={activeNavItem}

          content="Coin scalability is a crucial concept in the world of cryptocurrencies and blockchain technology. It refers to a coin's ability to handle a growing number of transactions and users without sacrificing performance, speed, or efficiency. Scalability is essential for the widespread adoption of cryptocurrencies, as it ensures that the network can accommodate increased demand while maintaining its functionality."

        />

      </div>

    </div>

  );

};

 

function NavItem({ index, activeNavItem, onClick, label }) {

  return (

    <li

      className={`nav-item ${index === activeNavItem ? "active" : ""}`}

      onClick={() => onClick(index)}

    >

      <a href="#about">{label}</a>

    </li>

  );

}

 

function ContentArea({ index, activeNavItem, content }) {

  return (

    <div className={`content-area ${index === activeNavItem ? "active" : ""}`}>

      {content}

    </div>

  );

}

 

export default About;