import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import "./AirDrop.css"

function TypewriterComponent() {
  const [text, setText] = useState('');
  const phrases = ["RAINY AIRDROPS !", "SUNNY AIRDROPS !", "WINTER AIRDROPS !"];
  const [phraseIndex, setPhraseIndex] = useState(0);

  useEffect(() => {
    const delay = 3000; // Delay between phrases

    const updateText = () => {
      // Type the current phrase
      setText(phrases[phraseIndex]);
    };

    // Start the initial typing
    updateText();

    // Set up a timer to switch phrases
    const interval = setInterval(() => {
      updateText();
      setTimeout(() => {
        // Clear the current phrase
        setText('');
        // Move to the next phrase or loop back to the first one
        setPhraseIndex((prevIndex) => (prevIndex === phrases.length - 1 ? 0 : prevIndex + 1));
      }, delay - 500); // Adjusted to match the Typewriter pauseFor duration
    }, delay);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [phraseIndex]);

  return (
    <div >
      <Typewriter
        options={{
          strings: [text],
          autoStart: true,
          loop: false,
        }}
      />
    </div>
  );
}

export default TypewriterComponent;
