import React, { useEffect, useState } from 'react';
import "./Token.css";

function Post(props) {
  const { title, content } = props;

  const [isSticky, setIsSticky] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  return (
    <div className={`article ${isSticky ? 'sticky' : ''} ${isHidden ? 'hide' : ''}`}>
      <header>
        <div className="stick">
          <h2>{title}</h2>
    
        </div>
      </header>
      <div className="content-article">
        {content.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
}

function FloatingHeaders() {
  const posts = [
  
    {
      title: 'TOKENOMICS',
      content: [
        <div className='token-p'>
        <p><br/>Tokenomics is the short form of Token + Economics. Tokenomics is the Economic Model fo a token that given the Crypton Project. The Issuance of the Currency in the Market should be done based on Complex Math and Economic Models.<br/>
        For Instance, The Government need to consider the Employment rate, loan rate, GDP and gazillion other factors for Currency they issues.<br/>
        Likewise, If they Launching a crypto Project, they need to treat the token as the Currency. So here Tokenomics defines the Economic Model of the Token. This is to ensure the Healthy Economic Growth.<br/>
        </p>
        </div>
      ],
    },
    {
        title: 'Token Distribution Strategies',
        content: [
            <ul className='unorder-list'>
          <br/> <li><h4>COIN CIRCULATION:</h4></li> 
           <p>Coin circulation refers to the number of coins from a cryptocurrency's total supply that are actively traded, held by users, or used in various transactions. A higher coin circulation typically indicates greater liquidity and activity within the cryptocurrency's network. When a cryptocurrency exhibits a 50% coin circulation, it signifies that half of the total supply is actively in use within the market. </p>
           <ol>
            <li>Burnings:</li>
            <p>Token burn events involve the deliberate destruction or removal of a portion of the cryptocurrency's supply. This process reduces the circulating supply and can be executed for various reasons, including deflationary purposes, enhancing scarcity, and increasing the value of existing tokens.</p>
            <li>Public Sale: </li>
            <p>The public sale category encompasses the subsequent token sales. During these events, a portion of the total supply is sold to early investors, raising funds for project development and distributing coins to a wider user base.</p>
            <li>Circulating Supply:</li>
            <p>A 50% coin circulation has the potential to influence market prices. The more tokens actively circulating, the more readily available they are for trading, which can affect supply and demand dynamics and, consequently, price stability.</p>
            <li>Maximum Supply:</li>
            <p> Maximum supply refers to the absolute maximum number of tokens that will ever exist for a particular cryptocurrency. In many cases, this number is fixed and cannot be exceeded. A 50% coin circulation signifies that half of this maximum supply has already been distributed or is actively circulating.</p>
           </ol>
           <li><h4>REWARDS:</h4></li>
           <p>A cryptocurrency ecosystem that offers a 10% rewards structure allocates a significant portion of its tokens to rewarding users. In this article, we'll explore the various categories of rewards within such a structure, including airdrops, welcome bonuses, referral bonuses, and staking rewards.</p>
           <ol>
            <li>Airdrops:</li>
            <p>Airdrops encourage users to engage with the cryptocurrency project. By receiving tokens for simply holding the cryptocurrency or participating in certain activities, users are motivated to remain actively involved.Airdrops can generate buzz and attract new users to the ecosystem. When recipients share their positive experiences, it can result in increased visibility and adoption.</p>
           <li>Welcome Bonuses:</li>
           <p>Welcome bonuses are designed to attract new users to a cryptocurrency platform. They are typically granted when users sign up or complete specific onboarding tasks.  Well-designed welcome bonuses can lead to increased user engagement and, ultimately, a more active and vibrant community.</p>
           <li>Referral Bonuses:</li>
           <p>Referral bonuses reward users for referring others to the platform. When a user brings in new participants, they receive a bonus, and the new users may also receive incentives. Users who refer others are often loyal advocates of the platform. They believe in the project's value and are willing to vouch for it. </p>
           <li>Staking Rewards:</li>
           <p> Staking rewards are earned by users who lock up their tokens in the network to support its operations. In proof-of-stake (PoS) or delegated proof-of-stake (DPoS) systems, stakers help secure the network and validate transactions. Staking rewards encourage long-term commitment to the ecosystem. Users are more likely to hold and stake their tokens, which can contribute to a stable and secure network. </p>
           </ol>
           
           <li><h4>TEAMS:</h4></li>
           <p>A Every successful cryptocurrency project lies a dedicated team of professionals working diligently to bring their vision to life. A well-structured cryptocurrency project typically allocates 5% of its resources to various teams, each specializing in a critical aspect of the project's development and success.</p>
           <ol>
            <li>Founding Team:</li>
            <p>The founding team serves as the project's leadership, making crucial decisions regarding its direction, partnerships, and strategic moves. They provide the project with a strong sense of purpose and direction. Founders often play a pivotal role in community engagement and building a supportive user base. They are the face of the project, fostering trust and transparency. </p>
           <li>Developers & Technical Team:</li>
           <p>The tech team comprises skilled blockchain developers responsible for building and maintaining the project's core technology. They ensure the security, scalability, and functionality of the blockchain or network.  In projects that use smart contracts, developers in this team specialize in creating and auditing smart contract code, ensuring its reliability and security.</p>
           <li>Legal & Advisory Team:</li>
           <p>The legal advisory team monitors and ensures the project's compliance with evolving cryptocurrency regulations in various jurisdictions. They help navigate complex legal landscapes to mitigate risks. Legal advisors help design tokenomics models that align with legal frameworks and advise on governance structures that promote transparency and adherence to laws. They draft and review contractual agreements with partners, stakeholders, and contributors to safeguard the interests of the project.</p>
           <li>Sales and Marketing Team:</li>
           <p>The sales and marketing team is responsible for attracting users, investors, and stakeholders to the project. They employ various strategies to expand the user base. This team cultivates and strengthens the project's brand identity, ensuring it resonates with the target audience and conveys trustworthiness. They engage with the community through social media, forums, and events, fostering a vibrant and supportive ecosystem around the project.</p>
           </ol>
           <li><h4>INITIAL COIN OFFERING:</h4></li>
           <p>An Initial Coin Offering (ICO) is a fundraising method employed by cryptocurrency projects to secure capital for development, operations, and growth with 5% Allocation Perspective. A 5% allocation in an ICO refers to the proportion of the total token supply that is made available for sale during the offering. This allocation can vary depending on the project's goals and needs.  The 5% allocation may be earmarked for early backers, which can include private investors, advisors, and strategic partners who contribute to the project's development and growth.</p>
           <ol>
            <li>Considerations for Investors:</li>
            <p>Due Diligence: Investors should conduct thorough research on the project, including its team, technology, and market potential. Scrupulous due diligence helps identify credible projects.</p>
            <p>Risks: ICOs carry inherent risks, including regulatory uncertainties, market volatility, and project failure. Investors should be prepared for the possibility of losing their entire investment.</p>
            <p>Long-Term Perspective: Rather than seeking quick profits, adopting a long-term perspective can be more rewarding in ICO investments. Holding tokens with strong fundamentals can yield better returns over time.</p>
           <li>Token Distribution:</li>
           <p>After the ICO, the allocated 5% of tokens is distributed to participants based on their contributions. Investors receive tokens that are transferable and can be traded on cryptocurrency exchanges.</p>
           <li>Token Utility: </li>
           <p>The utility of tokens should be clearly defined. Investors are more likely to participate if they understand the value proposition and use cases of the tokens.</p>
           </ol>
           <li><h4>ECOSYSTEM DEVELOPMENT:</h4></li>
           <p>Ecosystem development is a cornerstone of success for cryptocurrency projects. Allocating 20% of resources to ecosystem development reflects a commitment to fostering a vibrant and sustainable community.</p>
           <ol>
            <li>INOCYX Exchange (3%)</li>
            <p>Listing Strategy: The 3% allocation to exchanges focuses on listing IYX Coin on reputable cryptocurrency exchanges. Listing on established platforms increases accessibility and liquidity for traders and investors.</p>
            <p>Market Exposure: Being available on multiple exchanges exposes IYX Coin to a broader audience of potential users and investors, boosting market visibility.</p>
            <p>Trading Pairs: Expanding the number of trading pairs ensures that IYX Coin can be traded against various cryptocurrencies, increasing its utility and flexibility.</p>
            <li>INOCYX NFT Marketplace(3%)</li>
            <p>NFT Integration: Allocating resources to NFT development enables IYX Coin to explore opportunities within the growing NFT market. This might involve creating unique NFTs, supporting NFT marketplaces, or integrating NFT functionality into the IYX ecosystem.</p>
            <p>User Engagement: NFTs can enhance user engagement and promote artistic and creative expression within the IYX community. They can also be used to incentivize participation and rewards.</p>
            <p>Diversification: NFTs offer diversification beyond traditional cryptocurrency assets, attracting a different segment of users and collectors to the IYX ecosystem.</p>
            <li>INOCYX Learning (3%)</li>
            <p>Educational Content: The 3% allocation to learning involves creating educational materials, tutorials, and documentation to help users understand and navigate the IYX ecosystem effectively.</p>
            <p>Community Support: Learning initiatives can include providing user support, fostering a sense of community, and facilitating discussions to address user questions and concerns.</p>
            <p>Developer Resources: For developers interested in building on the IYX ecosystem, providing resources such as APIs, SDKs, and developer tools is crucial to encourage innovation and growth.</p>
            <li>IYX Coin Page (3%)</li>
            <p>Dedicated Information Hub: A dedicated IYX Coin page serves as a centralized source of information about the coin, its technology, use cases, and updates. It offers transparency and builds trust within the community.</p>
            <p>User-Friendly Interface: The page should feature a user-friendly interface with easy navigation, making it accessible to both new and experienced users seeking information about IYX Coin.</p>
            <p>Real-Time Updates: Keeping the page up to date with the latest news, developments, and announcements ensures that users have access to the most current information.</p>
            <li>Future Product Development (8%)</li>
            <p>Innovation: A substantial portion of the allocation, 8%, is dedicated to developing future products and services within the IYX ecosystem. This might include decentralized applications (dApps), financial tools, or partnerships that expand the ecosystem's utility.</p>
            <p>Long-Term Vision: Investing in future products aligns with a long-term vision for IYX Coin, ensuring its relevance and competitiveness in the ever-evolving cryptocurrency landscape.</p>
           </ol>
           <li><h4>TREASURY RESERVE:</h4></li>
           <p>A Treasury reserve is a financial cushion that plays a pivotal role in ensuring the long-term stability and sustainability of a project. Allocating 10% of resources to a treasury reserve reflects a commitment to responsible financial management.</p>
           <ol>
            <li>Purpose of the Treasury Reserve:</li>
            <p>The treasury reserve serves multiple purposes, including funding development, research, marketing, community initiatives, and responding to unexpected challenges or opportunities. </p>
            <li>Financial Security:</li>
            <p>A treasury reserve provides financial security, ensuring that the project has resources available to weather market volatility and external factors that may impact its operations.</p>
            <li>Stability and Sustainability:</li>
            <p>A treasury reserve enhances the project's stability by ensuring it has resources to navigate economic downturns or unexpected events. This, in turn, contributes to the project's long-term sustainability.</p>
            <li>Innovation and Growth: </li>
            <p>The treasury reserve enables innovation by providing the means to fund research and development. It supports the creation of new features, services, and partnerships that can drive the project's growth.</p>
            <li>Adaptability:</li>
            <p>Having a treasury reserve allows the project to adapt to changing market conditions and seize opportunities for expansion or improvement.</p>
           </ol>
            </ul>
        ],
    },
   
    {
      title: 'Hold On for Dear Life with IYX Coin',
      content: [
        <div className='token-p'>
        <p><br/> The Art of HODLing: A Guide to Long-Term Crypto Investment. "Hodling” refers to the buy-and-hold strategy. Buy-and-hold investors tend to hold their assets for an extended period of time to profit from the long-term value appreciation. In contrast, traders are much more active in transactions and seek returns by buying at low prices and selling at high prices.<br/>
        <br/>
        <ul>
            <li>Long-Term Confidence:</li>
            <p>HODLers of IYX Coin maintain a long-term perspective. They have confidence in the project's vision, technology, and its potential to deliver value over time.</p><br/>
            <li>Emotional Discipline:</li>
            <p>Successful HODLers exercise emotional discipline. They avoid making impulsive decisions driven by fear or greed, recognizing that market fluctuations are part of the cryptocurrency landscape.</p><br/>
            <li>Diversification:</li>
            <p>While HODLing IYX Coin is about long-term commitment, it's also advisable to diversify your cryptocurrency portfolio. Holding a variety of cryptocurrencies can spread risk and enhance long-term investment strategies.</p><br/>
            <li>Thorough Research: </li>
            <p>HODLers conduct thorough research before investing in IYX Coin. They assess the project's fundamentals, team, technology, and use cases to make informed investment decisions.</p><br/>
            <li>Secure Storage:</li>
            <p> Security is a priority for long-term investors. They store their IYX Coins in secure wallets, such as hardware wallets or cold storage, to protect against potential threats like theft or hacking.</p><br/>
            <li>Volatility Tolerance:</li>
            <p>HODLers of IYX Coin understand that cryptocurrency markets can be highly volatile. They prepare mentally for price fluctuations and avoid making rash decisions during market downturns.</p><br/>
        </ul>
        </p>
        </div>
      ],
    },
    {
        title: 'Benefits of HODLing IYX Coin',
        content: [
            <div className='token-p'>
            <p><br/>
               <ul>
                <li>The IYX token runs on the Ethereum network, enabling exchange on any ERC-20 network</li><br/>
                <li>Users with INOCYX Titans receive discounts and Rewards whilst trading the token (see appendix for details on how to qualify as a INOCYX Titans)</li><br/>
                <li>Upload a Youtube Shorts or Instagrams Reels or X Post about IYX coin and share the link and Inocyx account Number with Us, and get Free IYX coin as rewards.</li><br/>
                <li>INOCYX apportions tokens to the investor protection fund to insure users who fall victim to manipulative schemes or experience financial losses</li><br/>
                <li>By holding IYX Coins over the long term, you can potentially benefit from price appreciation driven by the project's development and adoption.</li><br/>
                <li>Increased referral rates that stake IYX are paid a higher fraction of their referees’ fees. </li><br/>
                <li>HODLing IYX Coin reduces the need for frequent trading, which can lead to transaction fees and tax implications. It also lowers the risk of making hasty trading decisions.</li><br/>
                <li>In the future, we will leverage proprietary data to present new shifts in adoption within the crypto ecosystem.</li><br/>
                <br/><li>Access to IYX Earn a suite of five passive income products: </li>
                 <ol>
                    <li>Liquidity mining with DeFi tokens,</li>
                    <li>Savings,</li>
                    <li>Staking,</li> 
                    <li>Staking on Ethereum 2.0,</li>
                    <li>P2P loans.</li></ol>
                    
                   </ul>        
            </p>
            </div>
        ],
},
{
    title: 'Token Burn and Buyback',
    content: [
        <div className='token-p'>
      <p><br/>Some projects implement token burn or buyback mechanisms to reduce the token supply, increasing scarcity and potentially driving up its value. This is often seen as a deflationary measure.<br/>
      Tokenomics should include mechanisms that incentivize users to participate in the network. Staking rewards, liquidity provision incentives, and yield farming are examples of strategies that encourage token holders to actively engage with the ecosystem.Tokens must have clear use cases within their respective ecosystems. They might be used for payment, governance, staking, or as a means of access to certain features or services. Utility gives tokens intrinsic value and incentivizes users to hold them.<br/>
      The way tokens are distributed among initial investors, founders, the community, and other stakeholders plays a crucial role in tokenomics. A fair and transparent distribution can build trust and attract a wider user base.<br/>
      Tokenomics also extends to the broader market. Factors like liquidity, trading volume, and market sentiment can influence a coin's value, often outside the control of the project itself. Regulatory compliance is crucial in the crypto space. Tokenomics should be designed with legal and regulatory constraints in mind to ensure the project's longevity.<br/>
      </p>
      </div>
    ],
  },

// {
//     title: 'Hold On for Dear Life with IYX Coin',
//     content: [
//         <p></p>
//     ],
// },
  ];

  return (
    <div className='float-main'>
    <div className="canvas">
      <header className="page-header">
        <h1>Tokenomics Of <b>I Y X </b></h1>
        <p className="summary">Economic Model Of Token - Version 2.0<br/>
        by INOCYX on 2023</p>
        
      </header>
      <section>
        {posts.map((post, index) => (
          <Post key={index} {...post} />
        ))}
      </section>
    </div>
    </div>
  );
}

export default FloatingHeaders;
