

import React from "react";

import FacebookIcon from '@mui/icons-material/Facebook';

import TelegramIcon from '@mui/icons-material/Telegram';

import InstagramIcon from '@mui/icons-material/Instagram';

import TwitterIcon from '@mui/icons-material/Twitter';

import LinkedInIcon from '@mui/icons-material/LinkedIn';

import YouTubeIcon from '@mui/icons-material/YouTube';

import "./Footer.css"

import footer from '../../assets/Images/footer.png';
import { Link } from "react-router-dom";
import ContactUS from "../ContactUs/Contactus";
import Team from "../Team/Team";

const YourComponent = () => {

  return (

<div className="footer-main">

  <div className="footer-container"><img src={footer}></img>

  <div className="footer-content">

    <div className="social-platform"><h4>INOCYX Social Platform</h4>

        <div className="footer-icons">

          <a href="https://www.facebook.com/people/Inocyx/100083436154588/"target="blank" ><div className="fce-icon"> <FacebookIcon/></div></a>

        <a href="https://t.me/inocyxiyx" target="blank" > <div className="tele-icon"><TelegramIcon/></div></a>

      <a href="https://www.instagram.com/inocyx/?igshid=YmMyMTA2M2Y%3D" target="blank"><div className="insta-icon"><InstagramIcon/></div></a>

        <a href="https://www.youtube.com/channel/UCv9OPOSaJqQAe7ljg2d5lBg" target="blank"><div className="you-icon"> <YouTubeIcon/></div></a>

        <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FINOCYX" target="blank"><div className="twi-icon"><TwitterIcon/></div></a>

        <a href="https://www.linkedin.com/company/inocyx/mycompany/" target="blank"> <div className="link-icon"><LinkedInIcon/></div></a>

       

       

        </div>

       

    </div>
<div className="footer-right">
    <div className="support">
      <h2>Features</h2>
        <ul className="support-list">
      <Link to="/Airdrop">  <li>Airdrops</li></Link>
      <Link to="/Tokenomics">   <li>Tokenomics</li></Link>   
      <Link to="/Ecosystem">  <li>Ecosystem</li></Link> 
      <Link to="/Buy"> <li>How to Buy</li></Link>     
      <li><Link to="ContactUS">Contact Us</Link></li>
</ul></div>
<div className="support">
      <h2>Company</h2>
        <ul className="support-list">
<Link to="/Term-conditions"> <li>Terms & Conditions</li></Link>
           

            <li>Privacy Policy</li>

            <li>Intellactual Property Rights</li>

            <li>Disclaimer</li>
            <li><Link to="Teams">Developing Team</Link></li>
</ul></div>

</div>

  </div>

  <div className="bottom-content">

        <h4>Copyright © 2023 INOCYX | All rights reserved</h4>

    </div>

    </div>

</div>

  );

};

 

export default YourComponent;

 