import React from "react";

import "./Applications.css";

import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";

import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";

import CurrencyBitcoinOutlinedIcon from "@mui/icons-material/CurrencyBitcoinOutlined";

import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";

import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";

import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";

const Card = ({ icon, title, text }) => {
  return (
    <div className="card001">
      <div className="icon001">{icon}</div>

      <p className="title001">{title}</p>

      <p className="text001">{text}</p>
    </div>
  );
};

const Applications = () => {
  return (
    <div className="main-applications">
      <div className="app-headers">
        Upcoming Applications and Services
      </div>

      <div className="application-content">
        <Card
          icon={<CurrencyExchangeOutlinedIcon fontSize="large" />}
          title="Payment Gateway"
          // text="Click to see or edit your profile page."
        />

        <Card
          icon={<MiscellaneousServicesOutlinedIcon fontSize="large" />}
          title="NFT and Land NFT(Applications)"
          // text="Click to see or edit your profile page."
        />

        <Card
          icon={<AccountBalanceWalletOutlinedIcon fontSize="large" />}
          title="Wallet Solutions(Future)"
          // text="Click to see or edit your profile page."
        />

        <Card
          icon={<CurrencyBitcoinOutlinedIcon fontSize="large" />}
          title="Blockchain Certifications"
          // text="Click to see or edit your profile page."
        />

        <Card
          icon={<SecurityOutlinedIcon fontSize="large" />}
          title="E-16(Future)"
          text="Click to see or edit your profile page."
        />

        <Card
          icon={<PrivacyTipOutlinedIcon fontSize="large" />}
          title="Interoperability(Future)"
          // text="Click to see or edit your profile page."
        />
      </div>
    </div>
  );
};

export default Applications;
