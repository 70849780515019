import React, { useEffect } from "react";

import "./loader.css";

import { preLoaderAnim } from "./loaderanimation";

 

const PreLoader = () => {

  useEffect(() => {

    preLoaderAnim();

  }, []);

 

  return (

    <div className="preloader">

      <div className="texts-container">

        <span className="gradient-text-load">Innovative</span>

        <span className="gradient-text-load">Yield</span>

        <span className="gradient-text-load">Xtraordinary</span>

      </div>

    </div>

  );

};

 

export default PreLoader;

 