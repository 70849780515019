import React, { Component } from "react";

import "./Team.css";

import Dev1 from "../../assets/Images/sandy.png";

import Dev2 from "../../assets/Images/Developer2.png";

import Dev3 from "../../assets/Images/Developer3.png";

import founder from "../../assets/Images/co-founder.png";

import ceo from "../../assets/Images/CEO.png";

import Bg from "../../assets/Images/MicrosoftTeams-image (14).png";

class Person extends Component {
  render() {
    const { name, title, imgSrc, bgImgSrc, imgStyle } = this.props;

    return (
      <div className="person">
        <div className="teams-container">
          <div className="container-inner">
            <img
              className="circle"
              src={bgImgSrc}
              alt={`${name}'s background`}
            />

            <img
              className={`img ${imgStyle}`}
              src={imgSrc}
              alt={`${name}'s image`}
            />
          </div>
        </div>

        <div className="divider"></div>

        <div className="name">{name}</div>

        <div className="team-title">{title}</div>
      </div>
    );
  }
}

class Team extends Component {
  render() {
    return (
      <div className="developer-container">
        <div className="team-headers">
          Our Team
        </div>
        <Person
          name="Mohan Babu V"
          title="Chief Executive Officer"
          imgSrc={ceo}
          bgImgSrc={Bg}
          imgStyle="img2"
        />
        
        <Person
          name="Yusuf Rahman R"
          title="Co-Founder & Director"
          imgSrc={founder}
          bgImgSrc={Bg}
          imgStyle="img5"
        />
          <Person
          name="Nizara Banu I"
          title="MERN Stack Developer"
          imgSrc={Dev2}
          bgImgSrc={Bg}
          imgStyle="img1"
        />
       <Person
          name="Santhana Prakash S"
          title="MERN Stack Developer"
          imgSrc={Dev1}
          bgImgSrc={Bg}
          imgStyle="img4"
        />
        <Person
          name="Monisha S"
          title="MERN Stack Developer"
          imgSrc={Dev3}
          bgImgSrc={Bg}
          imgStyle="img3"
        />
        
      </div>
    );
  }
}

export default Team;
