import React from 'react'
import "./About.css"
import ButtonSwap from "./ButtonSwap"
import CoinAnime from "../About/CoinAnime/CoinAnime"
//

function AboutUs() {
  return (
    <div className='about-main'>
        <div className='about-container02'>
            <div className='about-content-002'>
            <div className='left-side-02'>
                <div className='left-side-header-02'>
                     <h1 style={{fontSize: "2em", color: "#ff9e41"}}>INOCYX Remarkable Creation </h1>
                     <h1 className='aboutus-heading-01'>I Y X Coin</h1>
                    
                </div>
                <br/>
                <div className='left-side-02-phara'>
                <p>
                  The IYX token is more than a digital asset. It's a journey toward financial freedom and innovation. Whether you're an experienced crypto enthusiast or a newcomer to the world of blockchain.
                  <br/> The IYX token was First Deployed in BEB-20 (Binance smartchain) at 31 March 2022. Started with the rate of 2 INR, Now its Hiked to 8.5 INR   
                  <br/>The launch of IYX Token marks a Pivotal moment in the Fusion of Finance and Technology. Dont miss out on being part of this Transformative Journey towards a Decentralized Financial Future.
                  <br/>IYX is a utility token mostly function to operate in the INOCYX ecosystem, IYX token will be minted on Binance smartchain. A total of 2 billion IYX token will be minted, this will be the maximum supply.
                  <br/>INOCYX plans to make our users satisfied only through their good returns, so we offer events and giveaway to incentivize customers and launch new products to acquire users to increase the demand and supply for IYX.
                  <br/>Inocyx leads the charge in reshaping the financial world with this revolutionary digital currency. With IYX Token, your financial dealings are streamlined, crystal clear, and highly versatile.
                  <br/>Inocyx is a Centralized Exchange, open source blockchain project that enables the creation of digital assets and tokens on its platform.
                      
                </p>
</div>
               
            </div>
            <div className='right-side-01'>
            <CoinAnime/>
            </div>
            </div>
            <div > <a href='https://bscscan.com/token/0xe864ded3c759fd0b767662b7b399480a2aced97d' target='blank'><button className='contract-btn'><span>Click Me</span><span>Coin Contract</span></button></a></div>
            <div><ButtonSwap/></div>
            
        </div>
       
       
    </div>
  )
}

export default AboutUs