import React, { useEffect, useState } from 'react';
import "../../Token/Token.css";
function Post(props) {
    const { title, content } = props;
  
    const [isSticky, setIsSticky] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
  
    return (
      <div className={`article ${isSticky ? 'sticky' : ''} ${isHidden ? 'hide' : ''}`}>
        <header>
          <div className="stick">
            <h2>{title}</h2>
      
          </div>
        </header>
        <div className="content-article">
          {content.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    );
  }
  function TermsConditions() {
    const posts = [
    
      {
        title: 'Welcome to IYX Coin,',
        content: [
          <div className='token-p'>
               <p><br/>The website of IYX Coin Company (“IYX Coin,” “we,” or “us”).<br/>
These terms and conditions outline the rules and regulations for the use of IYX Coin's Website, located at www.iyxcoin.com.
By accessing this website, we assume you accept these terms and conditions. Do not continue to use IYX Coin if you do not agree to take all of the terms and conditions stated on this page.
The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice, and all Agreements: "User," "You," and "Your" refer to you, the person using this website and compliant with the Company's terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us" refer to our Company. "Party," "Parties," or "Us" refer to both the User and us. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the User in the most appropriate manner for the express purpose of using IYX Coin's services, in accordance with and subject to the prevailing law of in. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they are taken as interchangeable and therefore as referring to the same.
        </p>
        </div>
      ],
    },
    {
        title: 'Cookies',
        content: [
            <div className='token-p'>
          <p><br/>We employ the use of cookies. By accessing IYX Coin, you agree to the use of cookies in accordance with IYX Coin's Privacy Policy.
Most interactive websites use cookies to enhance user experience. Cookies are used by our website to enable the functionality of certain areas to make it easier for users visiting our website. Some of our affiliate/advertising partners may also use cookies.<br/>
          </p>
          </div>
        ],
      },
    
      {
        title: 'License',
        content: [
            <div className='token-p'>
          <p><br/>Unless otherwise stated, IYX Token and/or its licensors own the intellectual property rights for all material on IYX Coin. All intellectual property rights are reserved. You may access this from IYX Coin for your own personal use, subject to restrictions set in these terms and conditions.<br/>
          This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.</p>
          </div>
        ],
      },
     
      {
        title: 'You must not:',
        content: [
            <ul className='unorder-list'>
                <br/> <li>Republish material from IYX Token</li>
                <li>Sell, rent, or sublicense material from IYX Token </li>
                <li>Reproduce, duplicate, or copy material from IYX Token</li>
                <li>Redistribute content from IYX Token</li>
            </ul>
        ],
      },
      {
        title: 'User Content',
        content: [
            <div className='token-p'>
          <p><br/>Parts of this website offer an opportunity for users to post and exchange opinions and information. IYX Coin does not filter, edit, publish, or review User Content prior to its presence on the website. User Content does not reflect the views and opinions of IYX Coin, its agents, and/or affiliates. User Content reflects the views and opinions of the person who posts it. To the extent permitted by applicable laws, IYX Coin shall not be liable for User Content or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of User Content on this website.
IYX Coin reserves the right to monitor all User Content and to remove any User Content that can be considered inappropriate, offensive, or causes a breach of these Terms and Conditions. You hereby grant IYX Coin a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce, and edit any User Content in any and all forms, formats, or media.</p>
          </div>
        ],
      },
      {
        title: 'You warrant and represent that:',
        content: [
            <ul className='unorder-list'>
                <br/> <li>You are entitled to post User Content on our website and have all necessary licenses and consents to do so;</li>
                <li>Sell, rent, or sublicense material from IYX TokenUser Content does not invade any intellectual property rights, including copyright, patent, or trademark, of any third party; </li>
                <li>User Content does not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material, which is an invasion of privacy;</li>
                <li>User Content will not be used to solicit or promote business, custom, or present commercial activities or unlawful activity.</li>
            </ul>
        ],
      },
      {
        title: 'Hyperlinking to our Content, The following organizations may link to our website without prior written approval:',
        content: [
            <ul className='unorder-list'>
                <br/> <li>Cryptocurrency-related websites;</li>
                <li>Search engines; </li>
                <li>Online directory distributors may link to our website in the same manner as they hyperlink to the websites of other listed businesses; and</li>
                <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website.</li>
            </ul>
        ],
      },
      {
        title: 'These organizations may link to our home page, to publications, or to other website information, so long as the link:',
        content: [
            <ul className='unorder-list'>
                <br/> <li>is not in any way deceptive;</li>
                <li>does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and</li>
                <li>fits within the context of the linking party's site.</li>
                
            </ul>
        ],
      },
      {
        title: 'We may consider and approve other link requests from the following types of organizations:',
        content: [
            <ul className='unorder-list'>
                <br/> <li>Commonly known consumer and/or business information sources;</li>
                <li>Cryptocurrency forums and communities;</li>
                <li>Associations or other groups representing cryptocurrencies;</li>
                <li>Online directory distributors;</li>
                <li>Internet portals;</li>
                <li>Cryptocurrency-related service providers; and</li>
                <li>Educational institutions and trade associations specializing in cryptocurrencies.</li>
            </ul>
        ],
      },
      {
        title: 'We will approve link requests from these organizations if we decide that:',
        content: [
            <div className='token-p'>
          <p><br/>(a) the link would not make us look unfavorably to ourselves or to our accredited businesses;<br/> (b) the organization does not have any negative records with us;<br/> (c) the benefit to us from the visibility of the hyperlink compensates for the absence of IYX Coin; and <br/>(d) the link is in the context of general resource information.</p>
          </div>
        ],
      },
      {
        title: 'We will approve link requests from these organizations if we decide that:These organizations may link to our home page so long as the link:',
        content: [
            <div className='token-p'>
          <p><br/>(a) is not in any way deceptive; <br/>(b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and<br/> (c) fits within the context of the linking party's site.<br/> (d)No use of IYX Coin's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
          </div>
        ],
      },
      {
        title: 'I Frames',
        content: [
            <div className='token-p'>
          <p><br/>Without prior approval and written permission, you may not create frames around our webpages that alter in any way the visual presentation or appearance of our website.</p>
          </div>
        ],
      },
      {
        title: 'Content Liability',
        content: [
            <div className='token-p'>
          <p><br/>We shall not be held responsible for any User Content that appears on your website. You agree to protect and defend us against all claims arising on your website. No link(s) should appear on any website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of any third-party rights.</p>
          </div>
        ],
      },
      {
        title: 'Reservation of Rights',
        content: [
            <div className='token-p'>
          <p><br/>We reserve the right to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our website, you agree to be bound by and follow these linking terms and conditions.</p>
          </div>
        ],
      },
      {
        title: 'Removal of links from your website',
        content: [
            <div className='token-p'>
          <p><br/>If you find any link on your website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to do so or to respond to you directly.</p>
          </div>
        ],
      },
    ];
      
      return (
        <div className='float-main'>
        <div className="canvas">
          <header className="page-header">
            <h1>Terms and Conditions</h1>
            <p className="summary">
            by INOCYX on 2023</p>
            
          </header>
          <section>
            {posts.map((post, index) => (
              <Post key={index} {...post} />
            ))}
          </section>
        </div>
        </div>
      );
    }
    
    export default TermsConditions;