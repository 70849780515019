import React from 'react'
import buypic from "../../assets/Images/buy-pic.png"
import "./Buy.css"
import scan from "../../assets/Images/MicrosoftTeams-image (26).png"
function Buy() {
  return (
    <div id='howto-buy'>
    <div className='buy-main'>
        <div className='buy-main-container'>
          <div className='buy-header'>
          </div>
          <div className='leftside-buy-content'>
            <div className='leftside-content-header'>
              <h1>Cravings for IYX coin</h1>
              <div className='leftside-content-p'>
                <p><b>Experience Financial Freedom With INOCYX Exchange</b><br/>Where Every Transaction is a step Towards a Prosperous Future.</p>
              </div>
            </div>
            <div className='exchange'><img src={buypic} alt='fgtyuiop'/></div>
          <div className='scanner-main'>
            <div className='scanner-content'>
              <p>Unlock Exclusive Offers! Scan this QR Code to Reveal IYX Treasures. For Android and IOS Users.</p>
            </div>
            <div className='scanner'>
              <img src={scan} alt='ghjk'/>
            </div>
          </div>  
           
          </div>
          <div className='rightside-buy-content'>
            <div className='card-main '>
              <div className='card-main-header'>
                Begin Trading IYX coins and explore the
                  exciting world of Cryptocurrency markets. Happy Trading!
                  </div>
              <div className='card-001'>
                <div className='card-no-001'><h3>01</h3></div>
                <div className='card-001-content'><p>Register on the exchange - Begin by signing up or signing into our Inocyx Exchange Platform.</p></div>
              </div>
              <div className='card-001'>
                <div className='card-no-001'><h3>02</h3></div>
                <div className='card-001-content'><p>Complete KYC Verification - Verify your identity through a quick and easy KYC Process that takes only 2 minutes.</p></div>
              </div>
              <div className='card-001'>
                <div className='card-no-001'><h3>03</h3></div>
                <div className='card-001-content'><p>Add Bank Details - Securely input your bank account details for future transactions.</p></div>
              </div>
              <div className='card-001'>
                <div className='card-no-001'><h3>04</h3></div>
                <div className='card-001-content'><p>Deposit funds - Deposit either fiat currency or cryptocurrency into your exchange wallet</p></div>
              </div>
              <div className='card-001'>
                <div className='card-no-001'><h3>05</h3></div>
                <div className='card-001-content'><p>Buy IYX coin - Use your deposited funds to purchase IYX coins on the exchange.</p></div>
              </div>
              </div>
          
          </div>
         
        </div>
        <div className='buy-button-01'>
          <a href='https://inocyx.com/' target='blank'><button><b><span>Click Here</span><span>Connect to INOCYX Exchange</span></b></button></a></div>
    </div>
    </div>
  )
}

export default Buy