import "./EcoSystem.css";

import React, { useLayoutEffect, useRef } from "react";

import DrawSvg from "./DrawSvg";

import styled from "styled-components";

import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

import BackgroundImg from "../../assets/Images/iyxbg/5.png";

import "@fontsource/aclonica";

 

const Section = styled.section`

  min-height: 100vh;

  width: 100%;

  position: relative;

`;

 

// const BackgroundSection = styled(Section)`

//   background-image: url(${BackgroundImg});

//   background-size: 100% 100%;

//   background-position: center;

 

// `;

 

const Title = styled.h1`

  font-size: 2em;

  display: flex;

  color: #f2f0c9;

  justify-content: center;

  font-family: safira;

  align-items: center;

  padding-top: 3rem;

  background-clip: text;

  -webkit-background-clip: text;

  background-image: linear-gradient(90deg, rgba(45,96,231,1) 0%, rgba(169,81,52,1) 69%, rgba(242,154,49,1) 100%);

 

  @media (max-width: 40em){

    font-size:2em;

  }

`;

 

 

const Container = styled.div`

  width: 70%;

  height: 200vh;

  margin: 0 auto;

  display: flex;

  justify-content: center;

  align-items: center;

  position: relative;

 

@media (max-width: 64em){

  width: 80%;

}

 

@media (max-width: 48em){

  width: 90%;

}

 

`;

 

 

const SvgContainer = styled.div`

  display: flex;

  justify-content: center;

  align-items: center;

`;

 

const Items = styled.ul`

  list-style: none;

  width: 100%;

  height: 100%;

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;

 

  @media (max-width: 48em){

    width: 90%

  }

 

  & > *:nth-of-type(2n + 1) {

    justify-content: start;

 

    @media (max-width: 48em){

      justify-content: center;

    }

 

    div {

      border-radius: 50px 0 50px 0;

      text-align: right;

 

      @media (max-width: 48em){

        border-radius: 0 50px 0 50px;

        text-align: left;

 

        p{

          border-radius: 0 40px 0 40px;

        }

      }

    }

    p {

      border-radius: 40px 0 40px 0;

    }

  }

  & > *:nth-of-type(2n) {

    justify-content: end;

 

    @media (max-width: 48em){

      justify-content: center;

    }

    div {

      border-radius: 0 50px 0 50px;

      text-align: left;

    }

    p {

      border-radius: 0 40px 0 40px;

    }

  }

`;

const Item = styled.li`

  width: 100%;

  height: 100%;

  display: flex;

 

  @media (max-width: 48em){

    justify-content: flex-end !important;

  }

`;

const ItemContainer = styled.div`

  width: 40%;

  height: fit-content;

  padding: 1rem;

  border: 4px solid #e4ff18;

 

  @media (max-width: 48em){

    width: 70%;

  }

`;

const Box = styled.p`

  height: fit-content;

  color: #fff;

 

  backdrop-filter: blur(3px) saturate(200%);

  -webkit-backdrop-filter: blur(30px) saturate(500%);

  background-color: rgba(255, 255, 255, 0.248);

  padding: 1rem;

  position: relative;

  border: 3px solid #202020;

`;

const SubTitle = styled.span`

  display: block;

  font-size: 1.5em;

  font-weight: bold;

  font-family:caudexBold;

  text-transform: capitalize;

  color: rgb(240,232,119);

color: linear-gradient(90deg, rgba(240,232,119,1) 0%, rgba(204,236,8,1) 41%, rgba(65,237,20,1) 100%);

 

@media (max-width: 40em){

  font-size: 1.23em;

  font-weight: 600;

}

`;

const Text = styled.span`

display: block;

    font-family: caudex;

    font-size: 1em;

    text-transform: capitalize;

    font-weight: 600;

    margin: 0.5rem 0;

    color:white;

 

    @media (max-width: 40em){

      font-size: 0.75em;

    }

`;

const RoadMapItem = ({ title, subtext, addToRef }) => {

  return (

    <Item ref={addToRef}>

      <ItemContainer>

        <Box>

          <SubTitle>{title}</SubTitle>

          <Text>{subtext}</Text>

        </Box>

      </ItemContainer>

   </Item>

);

};

 

const Roadmap = () => {

  const revealRefs = useRef([]);

  revealRefs.current = [];

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {

    if (el && !revealRefs.current.includes(el)) {

      revealRefs.current.push(el);

    }

  };

  useLayoutEffect(() => {

    let t1 = gsap.timeline();

    revealRefs.current.forEach((el, index) => {

      t1.fromTo(

        el.childNodes[0],

        {

          y: "0",

        },

        {

         y: "-30%",

          scrollTrigger: {

           id: `section-${index + 1}`,

            trigger: el,

            start: "top center+=200px",

            end: "bottom center",

            scrub: true,

            //markers: true,

          },

        }

      );

    });

    return () => {};

  }, []);

  return (

    <div id="ecosystem">

    {/* <BackgroundSection> */}

      <Title>Our Ecosystem</Title>

      <Container>

        <SvgContainer>

          <DrawSvg />

        </SvgContainer>

        <Items>

          <Item>&nbsp;</Item>

          <RoadMapItem

            addToRef={addToRefs}

            title="Our Own Crypto-Exchange"

            subtext="Inovative Crypto Exchange - INOCYX Exchange"

          />

          <RoadMapItem

            addToRef={addToRefs}

            title="Our Own Learning Platform"

            subtext="Education in Internet - INOCYX Learning"

          />

          <RoadMapItem

            addToRef={addToRefs}

            title="Our Own NFT Marketplace"

            subtext="Art, Music, Land into NFT - INOCYX NFT"

          />

          <RoadMapItem

            addToRef={addToRefs}

            title="Our Own Metaverse"

            subtext="Reality Meets Virtual Frontier - INOVERSE"

          />

          {/* <RoadMapItem

            addToRef={addToRefs}

            title="Future Product"

            subtext="E-16 Link, etc.."

          />

           <RoadMapItem

            addToRef={addToRefs}

            title="Future Product"

            subtext="E-16 Link, etc.."

          /> */}

        </Items>

      </Container>

    {/* </BackgroundSection> */}

    </div>

  );

};

export default Roadmap;