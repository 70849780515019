
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import imag from "../assets/Images/coin.png"
import "./Navbar.css";
import Explore from "./AirDrop/ExploreAll/Explore";
import Token from "./Token/Token";

const Nav = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <nav className="navbar">
      <div className="container-002 blue circleBehind">
        <div className="main-logo"><img src={imag} className='nav-logo' alt='logo' width={233} height={84} /></div>


        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <a
              exact
              href="#home"
              className='list-items'
              activeClassName="active"
              onClick={handleClick}
            >
              Home
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              exact
              href="#features"
              className='list-items'
              activeClassName="active"
              onClick={handleClick}
            >
              Features
            </a>
          </li> */}
          <li className="nav-item">
            <Link to="Explore"
              activeClassName="active"
              className='list-items'
              onClick={handleClick}>
                 AirDrops
            </Link>
          </li>
          <li className="nav-item">
            <Link to="Token"
            exact
            activeClassName="active"
              className='list-items'
              onClick={handleClick}>
                 Tokenomics
              </Link>
          </li>
          <li className="nav-item">
            <a
              exact
              href="https://inocyx.com/" target="blank"
              activeClassName="active"
              className='list-items'
              onClick={handleClick}
            >
              How To Buy
            </a>
          </li>

        </ul>

        <div className="nav-icon" onClick={handleClick}>
          {click ? <CloseIcon sx={{ width: "4vw", height: "4vh" }} /> : <MenuIcon sx={{ width: "4vw", height: "4vh" }} />}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
