import React, { useRef } from "react";
import "./Wallets.css";
import styled, { keyframes } from "styled-components";
import logo1 from "../../assets/WalletsLogo/nft.svg"
import logo2 from "../../assets/WalletsLogo/ino (1).svg";
import logo3 from "../../assets/WalletsLogo/inoversenew.svg";
import logo4 from "../../assets/WalletsLogo/exchange.svg";
import logo5 from "../../assets/WalletsLogo/Coinbase-logo-1.svg";
import logo6 from "../../assets/WalletsLogo/1inch-dark.svg";
import logo7 from "../../assets/WalletsLogo/Exodus_logo_dark.svg";
import logo8 from "../../assets/WalletsLogo/Okx Logo Vector.svg";
import logo9 from "../../assets/WalletsLogo/logo-dark-mewwallet.svg";
import logo11 from "../../assets/WalletsLogo/argent-wallet.svg";
import logo12 from "../../assets/WalletsLogo/trezor-logo-svg-vector.svg";
import logo13 from "../../assets/WalletsLogo/logo_horizontal_blue trustwallet.svg";
import logo14 from "../../assets/WalletsLogo/MicrosoftTeams-image (10).png";
// import Insights from "../Insights/Insights";

const Section = styled.section`
  min-height: 50vh;
  width: 100%;
  overflow: hidden;
  background-image: url("../../assets/Images/14.jpg"); /* Add the URL to your image */
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  & > *:first-child {
    animation-duration: 20s;

    @media (max-width: 30em){
      animation-duration: 15s;
    }
  }

  & > *:last-child {
    animation-duration: 15s;

    @media (max-width: 30em){
      animation-duration: 10s;
    }

  }
`;

const move = keyframes`

0%{ transform: translateX(100%) };
100%{ transform: translateX(-100%) }

`;

const Row = styled.div`

  white-space: nowrap;
  box-sizing: content-box;
  margin: 2rem 0;
  display: flex;
  flex-wrap: nowrap;
  animation: ${move} linear infinite ${(props) => props.direction}
    ${(props) => props.duration};
`;

const ImgContainer = styled.div`
width: 10rem;
margin: 3px 2rem;
background-color: #e1d2a8ba;
border-radius: 10px;
cursor: pointer;

@media (max-width: 48em){
  width: 12rem;
}

@media (max-width: 30em){
  width: 10rem;
}

  img {
    width: 100%;
    height: 100%;
  }
`;

const Logos = ({ img, passRef }) => {
  let play = (e) => {
    passRef.current.style.animationPlayState = "running";
  };
  let pause = (e) => {
    passRef.current.style.animationPlayState = "paused";
  };
  return (
    <ImgContainer onMouseOver={(e) => pause(e)} onMouseOut={(e) => play(e)}>
      <img src={img} alt="The Logos" />
    </ImgContainer>
  );
};

const Marquee = () => {
  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);
  return (
    <>
      <div className="wallets-main">
        {/* <div className="Insights-header">
          <h3>Insights</h3>
        </div> */}
      {/* <Insights /> */}
      <div className="wallets-header">
        <h3>Products and Wallet Listed</h3>
      </div>
        <Section>
          <Row direction="none" ref={Row1Ref} duration="40s">
            <Logos img={logo11} passRef={Row1Ref} />
            <Logos img={logo12} passRef={Row1Ref} />
            <Logos img={logo13} passRef={Row1Ref} />
            <Logos img={logo14} passRef={Row1Ref} />
            <Logos img={logo5} passRef={Row1Ref} />
            <Logos img={logo6} passRef={Row1Ref} />
            <Logos img={logo7} passRef={Row1Ref} />
            <Logos img={logo8} passRef={Row1Ref} />
            <Logos img={logo9} passRef={Row1Ref} />
          </Row>
          <Row direction="reverse" ref={Row2Ref} duration="30s">
            <Logos img={logo1} passRef={Row2Ref} />
            <Logos img={logo2} passRef={Row2Ref} />
            <Logos img={logo3} passRef={Row2Ref} />
            <Logos img={logo4} passRef={Row2Ref} />
          </Row>
        </Section>
      </div>

      <div>
        
      </div>
    </>
  );
};

export default Marquee;
