import React from 'react';
import "./CoinAnime.css"
import coin from "../../../assets/Images/coin (2).png"

const MyComponent = () => {
  return (
    <div>
      <svg viewBox="0 0 1 1">
        <defs>
          {/* Define clipPaths and masks here */}
          <clipPath id="clip1" clipPathUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.0625"></circle>
          </clipPath>
          <mask id="mask1" maskContentUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.0625" fill="#fff"></circle>
            <circle cx="0.5" cy="0.5" r="-0.01" fill="#000"></circle>
          </mask>

          {/* Define clipPath2 and mask2 */}
          <clipPath id="clip2" clipPathUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.125"></circle>
          </clipPath>
          <mask id="mask2" maskContentUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.125" fill="#fff"></circle>
            <circle cx="0.5" cy="0.5" r="0.0525" fill="#000"></circle>
          </mask>

          {/* Define clipPath3 and mask3 */}
          <clipPath id="clip3" clipPathUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.1875"></circle>
          </clipPath>
          <mask id="mask3" maskContentUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.1875" fill="#fff"></circle>
            <circle cx="0.5" cy="0.5" r="0.115" fill="#000"></circle>
          </mask>

          {/* Define clipPaths and masks for clip4, clip5, etc. */}
          <clipPath id="clip4" clipPathUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.25"> </circle>
    </clipPath>
    <mask id="mask4" maskContentUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.25" fill="#fff"></circle>
      <circle cx="0.5" cy="0.5" r="0.1775" fill="#000"></circle>
    </mask>
    <clipPath id="clip5" clipPathUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.3125"> </circle>
    </clipPath>
    <mask id="mask5" maskContentUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.3125" fill="#fff"></circle>
      <circle cx="0.5" cy="0.5" r="0.24" fill="#000"></circle>
    </mask>
    <clipPath id="clip6" clipPathUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.375"> </circle>
    </clipPath>
    <mask id="mask6" maskContentUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.375" fill="#fff"></circle>
      <circle cx="0.5" cy="0.5" r="0.3025" fill="#000"></circle>
    </mask>
    <clipPath id="clip7" clipPathUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.4375"> </circle>
    </clipPath>
    <mask id="mask7" maskContentUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.4375" fill="#fff"></circle>
      <circle cx="0.5" cy="0.5" r="0.365" fill="#000"></circle>
    </mask>
    <clipPath id="clip8" clipPathUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.5"> </circle>
    </clipPath>
    <mask id="mask8" maskContentUnits="objectBoundingBox">
      <circle cx="0.5" cy="0.5" r="0.5" fill="#fff"></circle>
      <circle cx="0.5" cy="0.5" r="0.4275" fill="#000"></circle>
    </mask>
        </defs>
      </svg>
      <div className="container-003">
        {[...Array(8)].map((_, index) => (
          <div className="ring" key={index}>
            <img
              src={coin}
              alt={`Ring ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyComponent;
