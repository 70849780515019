import React, { useEffect } from 'react';
import 'swiper/css'; // Import Swiper CSS
import Swiper from 'swiper/bundle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import rainy from "../../../assets/Images/Explore/rain.jpg";
import sunny from "../../../assets/Images/Explore/savana.avif";
import winter from "../../../assets/Images/Explore/winter.jpg";
import Scoin from "../../../assets/Images/Explore/sunny.png";
import Rcoin from "../../../assets/Images/Explore/rainny.png";
import WCoin from "../../../assets/Images/Explore/snowy.png"

import './Explore.css';





function Explore() {
  useEffect(() => {
    // Function to initialize Swiper
    const initializeSwiper = () => {
      const swiper = new Swiper('.mySwiper', {
        navigation: {
          nextEl: '.swiper-next-button',
          prevEl: '.swiper-prev-button',
        },
        effect: 'fade',
        loop: true, // Use "true" for an infinite loop
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
      });

      swiper.on('slideChange', function (sld) {
        document.body.setAttribute('data-sld', sld.realIndex);
      });
    };

    // Call the initialization function
    initializeSwiper();
    
    // Clean up Swiper when the component unmounts
    return () => {
      const swiper = document.querySelector('.mySwiper');
      if (swiper && swiper.swiper) {
        swiper.swiper.destroy(true, true);
      }
    };
  }, []);



  return (


    <div className='explore-main'>
    <div className="container">

    <a href="/"> 
       <button className='explore-back-btn'><ArrowBackIosIcon/></button>
       </a>
     
      <div className="mySwiper">
        <div className="swiper-wrapper">
          {/* Slide 1: Beach */}
          <div className="swiper-slide" id="beach">
            <div className="left-side">
              <div className="main-wrapper">
                <h2 className="main-header">IYX DROPPING</h2>
                <h1 className="main-title">Rainy AirDrop</h1>
               
              </div>
              <div className="main-content">
                <div className="main-content__title">January to April</div>
                <div className="main-content__subtitle"> Details will be Updated Soon .. </div>
              
              </div>
            </div>
            <div className="center">
              <div className="right-side__img">
                <img className="bottle-bg" src={rainy} alt="Beach Background" />
                <img className='bottle-img' src={Rcoin} alt="rainy"/>
              </div>
            </div>
          </div>
          {/* Slide 2: Savanna */}
          <div className="swiper-slide" id="savanna">
            <div className="left-side">
              <div className="main-wrapper">
                <h3 className="main-header">IYX DROPPING</h3>
                <h1 className="main-title">Sunny Airdrop</h1>
                
              </div>
              <div className="main-content">
                <div className="main-content__title">May to August.</div>
                <div className="main-content__subtitle">Details will be Updated Soon ..</div>
                
              </div>
            </div>
            <div className="center">
              <div className="right-side__img">
                <img className="bottle-bg" src={sunny} alt="Savanna Background" />
                <img className='bottle-img' src={Scoin} alt="summer"/>
              </div>
            </div>
          </div>

          {/* Slide 3: Glacier */}
          <div className="swiper-slide" id="glacier">
            <div className="left-side">
              <div className="main-wrapper">
                <h3 className="main-header">IYX DROPPING</h3>
                <h1 className="main-title">Winter AirDrop </h1>
                
              </div>
              <div className="main-content">
                <div className="main-content__title">September to December.</div>
                <div className="main-content__subtitle">Details will be Updated Soon ..</div>
                
              </div>
            </div>
            <div className="center">
              <div className="right-side__img">
                <img className="bottle-bg" src={winter} alt="Glacier Background" />
                 <img className='bottle-img' src={WCoin} alt="winter"/>              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <div  className="swiper-button swiper-prev-button ">
        &#x2190;
        </div>
        <div className="swiper-button swiper-next-button">
        &#x2192;
        </div>
      </div>
      <div className="swiper-pagination"></div>
    </div>
    </div>
  );
}

export default Explore;
