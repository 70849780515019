import React, { useState, useRef } from "react";
import "./SliderComponent.css"; // Make sure to import your CSS file with the correct filename
import img1 from "../../../assets/Images/TOKENOMICSIMAGE/12.jpg"
import img2 from "../../../assets/Images/TOKENOMICSIMAGE/11.jpg"
import img3 from "../../../assets/Images/TOKENOMICSIMAGE/6.jpg"
import img4 from "../../../assets/Images/TOKENOMICSIMAGE/7.jpg"
import img5 from "../../../assets/Images/TOKENOMICSIMAGE/9.jpg"
import img6 from "../../../assets/Images/TOKENOMICSIMAGE/10.jpg"


function SliderItem({ imgSrc, imgAlt }) {
  return (
    <div className="slider__item">
      <img className="slider__image" src={imgSrc} alt={imgAlt} />
     
    </div>
  );
}

function Slider() { // Renamed this to "Slider" with a capital 'S'
  const sliderRef = useRef(null);
  const [start, setStart] = useState(true);
  const [end, setEnd] = useState(false);

  const handleScroll = () => {
    if (sliderRef.current.scrollLeft === 0) {
      setStart(true);
    } else {
      setStart(false);
    }

    const scrollDiff =
      Math.abs(
        sliderRef.current.scrollWidth - sliderRef.current.offsetWidth
      ) - sliderRef.current.scrollLeft;

    if (scrollDiff < 5) {
      setEnd(true);
    } else {
      setEnd(false);
    }
  };

  const handlePrevious = () => {
    sliderRef.current.scrollBy({
      left: sliderRef.current.offsetWidth * -1,
      behavior: "smooth",
    });
  };

  const handleNext = () => {
    sliderRef.current.scrollBy({
      left: sliderRef.current.offsetWidth,
      behavior: "smooth",
    });
  };

  return (
    <div className="slider">
      <div
        className="slider__content"
        ref={sliderRef}
        onScroll={handleScroll}
      >
        <SliderItem
          imgSrc={img1}
          imgAlt="Image 1"
        />
         <SliderItem
          imgSrc={img2}
          imgAlt="Image 1"
        />
         <SliderItem
          imgSrc={img3}
          imgAlt="Image 1"
        />
         <SliderItem
          imgSrc={img4}
          imgAlt="Image 1"
        />
         <SliderItem
          imgSrc={img5}
          imgAlt="Image 1"
        />
          <SliderItem
          imgSrc={img6}
          imgAlt="Image 1"
        />
        {/* Repeat for other slider items */}
      </div>
      
    </div>
  );
}

export default Slider; // Renamed the export to "Slider" with a capital 'S'
