import React, { useState } from "react";
import "./Contactus.css";


const ContactUS = () => {

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [submitMessage, setSubmitMessage] = useState(null);

 

  // Function to handle form submission

  const handleSubmit = async (e) => {
    e.preventDefault();
 // Create a data object with form values
  const data = {
  firstName,
  email,
  phone,
  message,
};

 

try {

  const response = await fetch("https://iyx-backend.vercel.app/post-form", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    // Submission was successful
    setSubmitMessage("Form submission successful");
    // Clear the form
    setFirstName("");
    setEmail("");
    setPhoneNumber("");
    setMessage("");

  } else {

    // Handle the error
    setSubmitMessage("Form submission failed");
  }
} catch (error) {
  console.error("An error occurred", error);
  setSubmitMessage("An error occurred");
}
};
  return (
    <div>
      <section className="contact-section">
        <form className="contactus-container" onSubmit={handleSubmit}>
          <h2>Reach Us</h2>
          <div className="contactus-row">
            <div className="contactus-col">
              <div className="inputbox">
              <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <span className="contactus-text">First Name</span>
                <span className="contactus-line"></span>
              </div>
            </div>
          </div>
          <div className="contactus-row">
            <div className="contactus-col">
              <div className="inputbox">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <span className="contactus-text">Email</span>
                <span className="contactus-line"></span>
              </div>
            </div>
            <div className="contactus-col">
              <div className="inputbox">
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                <span className="contactus-text">Number</span>
                <span className="contactus-line"></span>
              </div>
            </div>
          </div>
          <div className="contactus-row">
            <div className="contactus-col">
              <div className="inputbox textarea">
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
                <span className="contactus-text">Type Your Message...</span>
                <span className="contactus-line"></span>
              </div>
            </div>
          </div>
          <div className="contactus-row row1">
            <div className="contactus-col">
              <input type="submit" value="Submit" />
            </div>
          </div>
        </form>
        {submitMessage && <div className="submit-message">{submitMessage}</div>}
      </section>
    </div>
  );
};

export default ContactUS;