import React, { useState } from 'react';
import "./Tokenomics.css"
import { Link } from 'react-router-dom';
// import token001 from "../../assets/Images/tokenomics.png"

import SliderComponent from "./SliderComponent/SliderComponent"



function Main() {
 

  return (
    <div id='tokenomics'>
    <div className='tokenomics-main' >
      <div className='tokenomic-container'>
     {/* <div className='left-side-tokenomics'>
         <img src={token001} alt=''/>
         <div className='token-btn'>
        <a href='https://bscscan.com/token/0xe864ded3c759fd0b767662b7b399480a2aced97d#tokenAnalytics'target='blank'>
        <button><span>Click Me</span><span>Coin Analytics</span></button></a>
      </div>
     </div> */}
     <div className='right-side-tokenomics'>
      <div className='token-header'><h1>I Y X Tokenomics</h1></div>
      <div className='token-content-p'><p>Our Tokens will have a 24-months vesting period. This means that a portion of IYX tokens will be unlocked gradually over the course of two years. This approach prevents large dumps of tokens in the market that could negatively impact the token's value.</p><br/>
     
      <br/>
      <p>The Project has reached its development stage with the available technical infrastructures, the ensures the highest intrest for existing and future investors. Projects with long vesting periods tend to be more resilient and less prone to manipulation, ensuring a sustainable ecosystem.</p></div>
      <div className='tokenomics-btn'>
        <Link to= "/Token">
        <button><span>Read More</span><span>Read Tokenomics</span></button></Link>
      </div>
     </div>
     </div>
     <SliderComponent/>
    </div>
    </div>
  );
}









export default Main;
