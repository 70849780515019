// import React, { useEffect, useRef } from 'react';
// import * as THREE from 'three';
import React, { useState, useEffect } from 'react';
import "./Home.css"

// export default function ParticleExample() {
//   let camera;
//   let scene;
//   let renderer;
//   let material;
//   let mouseX = 0;
//   let mouseY = 0;
//   let windowHalfX = window.innerWidth / 2;
//   let windowHalfY = window.innerHeight / 2;

//   const containerRef = useRef(); // Reference to the container div

//   const onPointerMove = (event) => {
//     mouseX = (event.clientX / window.innerWidth) * 2 - 1;
//     mouseY = - (event.clientY / window.innerHeight) * 2 + 1;
//   };

//   const onWindowResize = () => {
//     // Update the window dimensions
//     const newWidth = window.innerWidth;
//     const newHeight = window.innerHeight;

//     // Update camera aspect ratio
//     camera.aspect = newWidth / newHeight;
//     camera.updateProjectionMatrix();

//     // Update renderer size
//     renderer.setSize(newWidth, newHeight);
//   };

//   const init = () => {
//     camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 5, 2000);
//     camera.position.z = 500;

//     scene = new THREE.Scene();
//     scene.fog = new THREE.FogExp2(0xFA502E, 0.002);

//     const geometry = new THREE.BufferGeometry();
//     const vertices = [];
//     const size = 2000;

//     for (let i = 0; i < 20000; i++) {
//       const x = (Math.random() * size + Math.random() * size) / 2 - size / 2;
//       const y = (Math.random() * size + Math.random() * size) / 2 - size / 2;
//       const z = (Math.random() * size + Math.random() * size) / 2 - size / 2;

//       vertices.push(x, y, z);
//     }

//     geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

//     material = new THREE.PointsMaterial({
//       size: 1,
//       color: 0xFA502E,
//     });

//     const particles = new THREE.Points(geometry, material);
//     scene.add(particles);

//     renderer = new THREE.WebGLRenderer();
//     renderer.setPixelRatio(window.devicePixelRatio);

//     // Set initial renderer size to match the container
//     renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);

//     containerRef.current.appendChild(renderer.domElement);

//     // document.body.style.touchAction = 'none';
//     // document.body.addEventListener('pointermove', onPointerMove);
//     // window.addEventListener('resize', onWindowResize);
//   };

//   const animate = () => {
//     requestAnimationFrame(animate);
//     render();
//   };

//   const render = () => {
//     camera.position.x += (mouseX * 2 - camera.position.x) * 0.02;
//     camera.position.y += (-mouseY * 2 - camera.position.y) * 0.02;
//     camera.lookAt(scene.position);

//     renderer.render(scene, camera);

//     scene.rotation.x += 0.001;
//     scene.rotation.y += 0.002;
//   };

//   useEffect(() => {
//     init();
//     animate();
  
//     // Cleanup function
//     return () => {
//       const rendererElement = renderer.domElement;
//       if (rendererElement && rendererElement.parentNode) {
//         rendererElement.parentNode.removeChild(rendererElement);
//       }
//       window.removeEventListener('resize', onWindowResize);
//     };
//   }, []);
  

//   return (
//     <div  className='home-main'>
//       {/* Use CSS to control container size */}
//       <div ref={containerRef} className='home-content' ></div>  
//    <div className='mobile-view-home'>
//     <div className='mobile-view-p'>
//       <h2>Buy and Trade..</h2>
//       <h2>Anywhere,Anytime</h2>
//     </div>
//     <div className='mob-heading'>
//       <h1>I Y X</h1>
//     </div>
//     </div> 
//     </div>
    
//   );
// }


// import React from 'react';


// Define AuroraItem component
function AuroraItem() {
  return <div className="aurora__item"></div>;
}

// Define Title component
function Title() {
  return (
    <h1 className="title-home">
      I Y X
      <div className="aurora">
        <AuroraItem />
        <AuroraItem />
        <AuroraItem />
        <AuroraItem />
      </div>
    </h1>
  );
}

// TextRotator component
const TextRotator = () => {
  const phrases = ['Buy and Trade', 'Procure and Exchange', 'Stock up and Swap out','Acquire and Handover','Purchase and Transfer'];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container-home">
      <h1 className="text-rotator">
        Cryptocurrency enchanced,
        <div className="rotate-home">
          <span className="rotate-item">{phrases[currentPhraseIndex]}</span>
        </div>
      </h1>

      {/* Call the Title component here */}
      <Title />
    </div>
  );
};

export default TextRotator;
