import React, { useState, useEffect, useRef } from 'react';
import "./AirDrop.css"
import videoBackground03 from "../../assets/Videos/airdrop (2).mp4"
import Typography from "./Typography"
import {Link} from 'react-router-dom';

const delay = 2500;

function AirDrop() {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slideImages.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);
  const slideImages = [
    require("../../assets/Images/1.jpg"),
    require("../../assets/Images/2.jpg"),
    require("../../assets/Images/4.jpg"),
    
    // Add more image URLs as needed
  ];
  
  console.log (slideImages, "images");
  

  return (
    <div id='airdrop'>
    <div className="video-background">

      {/* <video autoPlay muted loop>
        <source src={videoBackground03} type="video/mp4"  />
       
      </video> */}

      <div className='Airdrop-container'>
        <div className='left-slideshow'>
          <div className='airdrop-header'>
            <h1>Discovering SEASONAL Airdrops</h1>
          </div>
          <div className='gradient-text'><Typography/></div>
          <div className='phara-01'>
            <p>Every year, We're giving away a special airdrop not once, not twice, but THREE times annually!.</p>
            <p>Our Annual Airdrop Program is more than just free tokens; it's a way for us to express our gratitude for your unwavering support.</p><br/>
            <p><b>Three Times a Year:</b> Our Annual Airdrop Program consists of three separate airdrops spread across the year. You'll receive tokens on specific dates, so mark your calendars!</p><br/>
            <p><b>10% Overall:</b> Over the course of the year 3.3% + 3.3% + 3.3%, you'll accumulate a total of 10% in airdropped tokens. It's our way of continuously rewarding your loyalty.</p><br/>
            <p><b>Note: </b><i>AirDrops depends on Pay-In Values.</i></p>

          </div>
          <div className='airdrop-explore-btn'>
      
        <Link to="/Explore">
        <button><span>read more</span><span>Explore Now</span></button>
      </Link>
        
          </div>
        </div>
        <div className="slideshow">
  <div
    className="slideshowSlider"
    style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
  >
    {slideImages.map((imageURL, idx) => (
      <div
        className="slide"
        key={idx}
        style={{ backgroundImage: `url(${imageURL})` }}
      ></div>
    ))}
  </div>

  <div className="slideshowDots">
    {slideImages.map((_, idx) => (
      <div
        key={idx}
        className={`slideshowDot${index === idx ? " active" : ""}`}
        onClick={() => {
          setIndex(idx);
        }}
      ></div>
    ))}
  </div>
</div>

      </div>
    </div>
   </div>
  );
}

export default AirDrop;
