import React, { useLayoutEffect, useRef } from "react";
import { useWindowScroll } from "react-use";
import { styled } from "styled-components";

const Up = styled.a`
  width: 5rem;
  height: 4rem;
  box-sizing: content-box;
  margin: 2px;
  padding: 8px;
  color: #020202;
  background-color: rgb(233 127 14);
  backdrop-filter: blur(15px);
  font-size: 1em;
  position: fixed;
  right: 6rem;
  bottom: 3rem;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 46px 6px;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(0.9);


    @media (max-width : 40em){
      padding: 2px;
      right: 1rem;
      bottom: 1rem;
  }
`;

const ScrollToTop = () => {
  const ref = useRef(null);
  const { y } = useWindowScroll();

  const redirectToWebsite = () => {
    // Replace 'https://example.com' with the URL you want to redirect to.
    window.location.href = "https://inocyx.com/";
  };

  useLayoutEffect(() => {
    if (y > 200) {
      ref.current.style.display = "flex";
    } else {
      ref.current.style.display = "none";
    }
  }, [y]);

  return (
    <Up ref={ref} onClick={() => redirectToWebsite()}>
      Buy Now
    </Up>
  );
};

export default ScrollToTop;
