import React from "react";
import { Routes, Route } from 'react-router-dom';
import "./App.css";
import AirDrop from "./components/AirDrop/AirDrop";
import Ecosystem from "./components/EcoSystem.js/EcoSystem";
import Tokenomics from "./components/Tokenomics/Tokenomics";
import Buy from "./components/Buy/Buy";
 import TermsConditions from "./components/Footer/Policies/PrivacyPolicy";

import Token from "./components/Token/Token"
// import Footer from "./components/Footer/Footer";
import Explore from "./components/AirDrop/ExploreAll/Explore";
import ContactUS from "./components/ContactUs/Contactus";
import Team from "./components/Team/Team";

import Tree from "./Tree";


function App() {
  return (
    <div className="App" >
      {/* Common components */}
      
      {/* <Home/>
      <AirDrop/>
      <AboutUs/>
      <Tokenomics/>
      <Applications />
      <Footer /> */}
      <Routes>
       <Route path='/' element={<Tree/>}></Route>
        <Route path="/Explore" element={<Explore/>}/>
        <Route path="/Token" element={ <Token/>} />
        <Route path="/Contactus" element={ <ContactUS/> } />
        <Route path="/Teams" element={ <Team/> } />
        <Route path="/Airdrop" element={<AirDrop/>}/>
        <Route path="/Tokenomics" element={<Tokenomics/>}/>
        <Route path="/Ecosystem" element={<Ecosystem/>}/>
        <Route path="/Buy" element={<Buy/>}/>
        <Route path="/Term-conditions" element={<TermsConditions/>}/>
     </Routes>
     {/* <Explore/> */}
     {/* <AirDrop />
     <AboutUs />
     <Applications />
     <Footer /> */}
     {/* <WalletList/> */}
   
     
     
     


 

     

      {/* Route configuration */}
  
      
   
    </div>
  );
}

export default App;
