import React from 'react'
import AirDrop from "./components/AirDrop/AirDrop";
import AboutUs from "./components/About/AboutUs";
import Tokenomics from "./components/Tokenomics/Tokenomics";
import Applications from "./components/Applications/Applications";
import Home from "./components/Home/Home";

import Footer from "./components/Footer/Footer";
import EcoSystem from "./components/EcoSystem.js/EcoSystem"
import WalletList from "./components/WalletList/WalletList"
import Navbar from "./components/Navbar"
import Buy from './components/Buy/Buy';

import Loader from './components/Preloader/loader';
import Test from './components/testing/test';
import Scroller from './components/scroller/scroller';



function Tree() {
  return (
    <>
    <Loader />
    <div>
          <Navbar/>
          <Home/>
          <AboutUs/> 
           <AirDrop /> 
          
          <Tokenomics/>
          <EcoSystem/>
          <Buy/>
          {/* <Test /> */}
          <Applications />
          <WalletList/>  
          <Footer />  
        <Scroller />   

       
       
       
    </div>
    </>
  )
}

export default Tree;